window.addEventListener('DOMContentLoaded', () => {
  const passwordComponents = document.querySelectorAll(
    '[data-js=password-toggle]'
  );

  passwordComponents.forEach(component => {
    const instance = {
      component: component,
      inputEl: component.querySelector('input[type=password]'),
      toggleEl: component.querySelector('[data-js=password-toggle-link]')
    };

    component.classList.add('relative');
    instance.toggleEl.innerText = 'Show';
    instance.toggleEl.addEventListener(
      'click',
      handleTogglePasswordClick.bind(instance)
    );
  });
});

function handleTogglePasswordClick() {
  const inputAttr = this.inputEl.getAttribute('type');

  if (inputAttr === 'password') {
    this.inputEl.setAttribute('type', 'text');
    this.toggleEl.innerText = 'Hide';
  } else {
    this.inputEl.setAttribute('type', 'password');
    this.toggleEl.innerText = 'Show';
  }
}
